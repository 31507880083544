import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css']
})
export class MainNavComponent {

  show = false;

  constructor(public auth: AuthService) { }

  toggleCollapse() {
    this.show = !this.show;
  }

  logout() {
    this.auth.signOut();
  }
}
