import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { UserLoginComponent } from './user-login/user-login.component';
import { HomePageComponent } from './home-page/home-page.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserFormComponent } from './user-form/user-form.component';
import { OrderOverviewComponent } from './order-overview/order-overview.component';
import { PackingSlipComponent } from './packing-slip/packing-slip.component';
import { OrderService } from './order-overview/order.service';
import { PackingSlipService } from './packing-slip/packing-slip.service';
import { SystemLogsComponent } from './system-logs/system-logs.component';
import { SystemSettingsComponent } from './system-settings/system-settings.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { FireFormDirective } from '../fire-form.directive';
import { MatTabsModule } from '@angular/material';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatSliderModule,
  MatSlideToggleModule
} from '@angular/material';
import { ExpensesPageComponent } from './expenses-page/expenses-page.component';
import { ExpenseDetailPageComponent } from './expensesdetail-page/expensedetail-page.component';
import { QuantityChangelogComponent } from './quantity-changelog/quantity-changelog.component';
import { PostingListComponent } from './posting-list/posting-list.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    BrowserModule,
    NgxSmartModalModule.forRoot(),
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatTabsModule
  ],
  declarations: [
    UserLoginComponent,
    HomePageComponent,
    MainNavComponent,
    LoadingSpinnerComponent,
    NotificationMessageComponent,
    UserProfileComponent,
    UserFormComponent,
    OrderOverviewComponent,
    PackingSlipComponent,
    SystemLogsComponent,
    QuantityChangelogComponent,
    SystemSettingsComponent,
    ExpensesPageComponent,
    ExpenseDetailPageComponent,
    FireFormDirective,
    PostingListComponent
  ],
  exports: [
    MainNavComponent,
    LoadingSpinnerComponent,
    NotificationMessageComponent,
    UserProfileComponent,
    UserFormComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSliderModule,
    MatSlideToggleModule
  ],
  providers: [
    OrderService,
    PackingSlipService
  ]
})
export class UiModule {}
