import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ToasterService } from 'angular2-toaster';
import {OrderService} from '../order-overview/order.service';

@Injectable()
export class PackingSlipService {

  toasterService: ToasterService;
  orderService: OrderService;
  lotQuantityQueueItemCollection: AngularFirestoreCollection<any>;

  constructor(
    private afs: AngularFirestore,
    toasterService: ToasterService,
    orderService: OrderService
) {
    this.toasterService = toasterService;
    this.orderService = orderService;
    this.lotQuantityQueueItemCollection = this.afs.collection('queue_lot_quantity');
  }

  getOrderItem(order_source: string, order_id: string, batch_nr: number, lot_id: string) {
    try {
      return this.afs.doc<any>(`orders/${order_source}_${order_id}/items/${batch_nr}_${lot_id}`);
    } catch (e) {
      console.log(e);
    }
  }

  updatePickStatus(order_source: string, order_id: string, batch_nr: number, lot_id: string, status: boolean) {
    let message = 'Setting status of lot ' + lot_id + ' of ' + order_source + ' order ' + order_id
      + ' batch #' + batch_nr + ' to ' + status;
    this.orderService.sendRemoteLogMessage(order_source, order_id, message, 'success', 'UI', null);

    return this.getOrderItem(order_source, order_id, batch_nr, lot_id).update({'pick_status': status})
      .catch( (e) => {
        console.log(e);
        message = 'Failed to set status of lot ' + lot_id + ' of ' + order_source + ' order ' + order_id
          + ' batch #' + batch_nr + ' to ' + status;
        console.log(message);
        this.orderService.sendRemoteLogMessage(order_source, order_id, message, 'error', 'UI', e.toString());
      });
  }

  getPartImageLink(item_type, item_no, color_id) {
    const imgUrl = 'https://www.bricklink.com/catalogItemPic.asp?';
    let code = 'P';
    // console.log(item_type);
    if (item_type === 'PART') {
      code = 'P';
    }
    if (item_type === 'MINIFIG') {
      code = 'M';
    }
    if (item_type === 'SET') {
      code = 'S';
    }
    return imgUrl + code + '=' + item_no;
  }

  getPartImageLocation(item_type, item_no, color_id) {
    const imgUrl = 'https://img.bricklink.com/';
    let color_code = color_id + '/';
    if (item_type === 'MINIFIG' || item_type === 'SET') {
      color_code = '/';
    }
    return imgUrl + item_type[0] + '/' + color_code + item_no + '.jpg';
  }

  htmlDecode(input) {
    const e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  }

  reduceInventoryLotByOne(order_source, order_id, lotId, batchNr) {
    const message = 'Schedule to reduce lot Id ' + lotId + ' by 1';
    this.orderService.sendRemoteLogMessage(order_source, order_id, message, 'success', 'UI', null);

    const lotInventoryQuantityQueueItem = {
      order_id: order_id,
      order_source: order_source,
      lot_id: lotId,
      batch_nr: batchNr,
      processed: false,
      timestamp: new Date(),
    };
    return this.lotQuantityQueueItemCollection.add(lotInventoryQuantityQueueItem);
    }

    onImageError(event) {
    console.log('Handling broken image ');
    console.log(event);
      const errorImage = '/assets/images/no_image.png';
      console.log('Replacing with local error image' + errorImage);
      event.target.src = errorImage;
    }
}
