import {Component, ViewEncapsulation} from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {Meta, Title, TransferState} from '@angular/platform-browser';
import {OrderService} from '../order-overview/order.service';
import {switchMap} from 'rxjs/operators';

export interface Item {
  order_id: string;
  order_status: string;
  source: string;
}

@Component({
  selector: 'expenses-page',
  templateUrl: './expenses-page.component.html',
  styleUrls: ['./expenses-page.component.scss']
})

export class ExpensesPageComponent {

  private toasterService: ToasterService;
  periodFilterValue;
  expenseItems$: Observable<Item[]>;
  expensePeriodFilter$: BehaviorSubject<number|null>;
  expenseStampsComItems$: Observable<Item[]>;
  expenseStampsComPeriodFilter$: BehaviorSubject<number|null>;

  constructor(
    public route: ActivatedRoute,
    private afs: AngularFirestore,
    private meta: Meta,
    private titleService: Title,
    private logState: TransferState,
    public orderService: OrderService,
    private fb: FormBuilder,
    toasterService: ToasterService
  ) {
    this.toasterService = toasterService;
    // Warenpost
    this.expensePeriodFilter$ = new BehaviorSubject(null);
    this.expenseItems$ = combineLatest(
      this.expensePeriodFilter$
    ).pipe(switchMap(([period]) =>
      afs.collection<Item>('warenpost_expenses', ref => {
        this.periodFilterValue = period;

        // Basic query
        let query: firebase.firestore.Query = ref;

        // Specific order
        if (period) {
          console.log('Expense period ' + period);
          console.log(period.toString());
          query = query.where('period', '==', + period);
        }
        // Limit to max 100
        return query.orderBy('period', 'desc').limit(100);
      }).valueChanges()
    ));
    // Stamps.com
    this.expenseStampsComPeriodFilter$ = new BehaviorSubject(null);
    this.expenseStampsComItems$ = combineLatest(
      this.expenseStampsComPeriodFilter$
    ).pipe(switchMap(([period]) =>
      afs.collection<Item>('stampscom_expenses', ref => {
        this.periodFilterValue = period;

        // Basic query
        let query: firebase.firestore.Query = ref;

        // Specific order
        if (period) {
          console.log('Expense period ' + period);
          console.log(period.toString());
          query = query.where('period', '==', + period);
        }
        // Limit to max 100
        return query.orderBy('period', 'desc').limit(100);
      }).valueChanges()
    ));
  }
}
