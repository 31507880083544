import { Component } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Meta, Title, TransferState } from '@angular/platform-browser';
import { OrderService } from '../order-overview/order.service';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Item } from '../order-overview/order-overview.component';
import { FormBuilder, FormGroup } from '@angular/forms';

export interface LogMessage { name: string; }

export interface Item {
  order_id: string;
  order_status: string;
  source: string;
}

@Component({
  selector: 'quantity-changelog',
  templateUrl: './quantity-changelog.component.html',
  styleUrls: ['./quantity-changelog.component.scss']
})
export class QuantityChangelogComponent {

  logItems;
  private toasterService: ToasterService;
  orderIdFilterValue;
  qtyItems$: Observable<Item[]>;
  orderIdFilter$: BehaviorSubject<number|null>;
  mySearchForm: FormGroup;

  constructor(
    public route: ActivatedRoute,
    private afs: AngularFirestore,
    private meta: Meta,
    private titleService: Title,
    private logState: TransferState,
    public orderService: OrderService,
    private fb: FormBuilder,
    toasterService: ToasterService
  ) {
    this.toasterService = toasterService;
    this.orderIdFilter$ = new BehaviorSubject(null);
    this.qtyItems$ = combineLatest(
      this.orderIdFilter$
    ).pipe(switchMap(([order_id]) =>
      afs.collection<Item>('quantity_change_log', ref => {
        this.orderIdFilterValue = order_id;

        // Basic query
        let query: firebase.firestore.Query = ref;

        // Specific order
        if (order_id) {
          console.log('Order id is ' + order_id);
          console.log(order_id.toString());
          query = query.where('order_id', '==', + order_id);
        }
        // Limit to max 200
        return query.orderBy('timestamp', 'desc').limit(200);
      }).valueChanges()
    ));
  }
}
