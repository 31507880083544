import { Component, OnInit } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { makeStateKey, Meta, Title, TransferState } from '@angular/platform-browser';
import { map, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';
import { OrderService } from '../order-overview/order.service';

const COUNTRY_VALUE_MAP_ITEM_DATA = makeStateKey<any>('expenseDetailItems');
const FEEDBACK_MAP_ITEM_DATA = makeStateKey<any>('expenseDetailItems');

export interface Credential { name: string; }

@Component({
  selector: 'system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.scss']
})
export class SystemSettingsComponent implements OnInit {

  countryValueItems;
  feedbackMapItems;
  countryValueMapSubscription;
  feedbackMapSubscription;
  private toasterService: ToasterService;
  myCountryForm: FormGroup;
  myCredentialForm: FormGroup;
  myFeedbackForm: FormGroup;
  credentials$: Observable<{}>;
  credentialKeys: string[] = [];
  counter = Array;

  constructor(
    public route: ActivatedRoute,
    private afs: AngularFirestore,
    private meta: Meta,
    private titleService: Title,
    private countryValueMapState: TransferState,
    private feedbackMapState: TransferState,
    private credentialState: TransferState,
    toasterService: ToasterService,
    public orderService: OrderService,
    private fb: FormBuilder
  ) {
    this.toasterService = toasterService;
    this.myCountryForm = this.fb.group({
      country_code: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(2)]],
      country_value: ['', [Validators.required, Validators.pattern('^[0-9]*$'),
        Validators.maxLength(5), Validators.min(1), Validators.max(99999)]],
    });
    this.myFeedbackForm = this.fb.group({
      index: ['', [Validators.required] ],
      feedback: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(100)]],
    });
    this.myCredentialForm = this.fb.group({
      plain: ['', [Validators.required]],
      credential_key: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    // warenpost
    const countryValueMapItems$ = this.afs.doc('settings/country_value_map').valueChanges();
    this.countryValueMapSubscription = countryValueMapItems$.pipe(
      tap(countryValueMapList => {
        this.countryValueMapState.set(COUNTRY_VALUE_MAP_ITEM_DATA, countryValueMapList);
        this.countryValueItems = countryValueMapList;
        console.log(this.countryValueItems);
      })
    ).subscribe();
    // feedback
    const feedbackMapItems$ = this.afs.doc('settings/feedback').valueChanges();
    this.feedbackMapSubscription = feedbackMapItems$.pipe(
      tap(feedbackMapList => {
        this.feedbackMapState.set(FEEDBACK_MAP_ITEM_DATA, feedbackMapList);
        this.feedbackMapItems = feedbackMapList;
        console.log(this.feedbackMapItems);
      })
    ).subscribe();
    // credentials
    this.credentials$ = this.afs.collection<Credential>('credentials/', ref => ref).snapshotChanges().pipe(
      map(actions => {
        this.credentialKeys = [];
        return actions.map(a => {
              const data = a.payload.doc.data() as Credential;
              const id = a.payload.doc.id;
              this.credentialKeys.push(id);
              return { id, ...data };
            });
        })
    );
  }
  // Country value map
  insertCountry(country_code: string, country_value: number) {
    const message = 'Value for country code ' + country_code + ' was added';
    this.orderService.sendRemoteLogMessage(null, 0, message, 'success', 'UI', 'Country ' + country_code + ': ' + country_value + ' EUR');
    this.afs.doc('settings/country_value_map').update({ [country_code.toUpperCase()]: country_value });
    this.myCountryForm.reset();
  }
  deleteCountry(country_code) {
    const message = 'Value for country code ' + country_code + ' was removed';
    this.orderService.sendRemoteLogMessage(null, 0, message, 'success', 'UI');
    this.afs.doc('settings/country_value_map').update({ [country_code]: firestore.FieldValue.delete() });
  }
  // Credentials
  updateCredential(key: string, value: number) {
    const message = 'Updating credential ' + key + '. Encryption started in background.';
    this.orderService.sendRemoteLogMessage(null, 0, message, 'success', 'UI');
    this.afs.doc('credentials/' + key).update({ 'plain': value });
    this.credentialKeys = [];
    this.myCredentialForm.reset();
  }
  // Feedback
  insertFeedback(index: string, feedback: string) {
    const message = 'Feedback index ' + index + ' was added: ' + feedback;
    this.orderService.sendRemoteLogMessage(null, 0, message, 'success', 'UI', 'Feedback  #' + index + ': ' + feedback);
    this.afs.doc('settings/feedback').update({ [index]: feedback });
    this.myCountryForm.reset();
  }
  deleteFeedback(index) {
    const message = 'Feedback index #' + index + ' was removed';
    this.orderService.sendRemoteLogMessage(null, 0, message, 'success', 'UI');
    this.afs.doc('settings/feedback').update({ [index]: firestore.FieldValue.delete() });
  }
}
