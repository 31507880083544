import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import { makeStateKey, Meta, Title, TransferState } from '@angular/platform-browser';
import {map, tap} from 'rxjs/operators';
import { OrderService } from '../order-overview/order.service';
import { PackingSlipService } from './packing-slip.service';
import { ToasterService } from 'angular2-toaster';
import {Observable} from 'rxjs';
import {LogMessage} from '../system-logs/system-logs.component';
import {FormBuilder, FormGroup} from '@angular/forms';

const ORDER_ITEM_DATA = makeStateKey<any>('orderItems');
const ORDER_MESSAGE_DATA = makeStateKey<any>('messageItems');
const BUYER_NOTES_DATA = makeStateKey<any>('notes_on_buyer');
const ORDER_DATA = makeStateKey<any>('order');

export interface OrderMessage { name: string; }
export interface OrderItem { name: string; }

@Component({
  selector: 'packing-slip',
  templateUrl: './packing-slip.component.html',
  styleUrls: ['./packing-slip.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PackingSlipComponent implements OnInit {

  orderItems;
  order;
  orderMessageItems;
  notesOnBuyer;
  orderItemSubscription;
  orderMessageSubscription;
  orderSubscription;
  notesOnBuyerSubscription;
  private toasterService: ToasterService;
  myNoteForm: FormGroup;

  constructor(
    public route: ActivatedRoute,
    private afs: AngularFirestore,
    private meta: Meta,
    private titleService: Title,
    private itemState: TransferState,
    private orderMessageState: TransferState,
    private notesOnBuyerState: TransferState,
    private orderState: TransferState,
    public orderService: OrderService,
    public packingSlipService: PackingSlipService,
    toasterService: ToasterService,
    private fb: FormBuilder
  ) {
    this.toasterService = toasterService;
    this.myNoteForm = this.fb.group({
      note: ''
    });
  }

  ngOnInit() {
    const order_id = +this.route.snapshot.params['id'];
    const order_source = this.route.snapshot.params['source'];
    console.log(order_source + ' order ' + order_id);

    // Order items
    const orderItems$ = this.afs.collection('orders/' + order_source + '_' + order_id + '/items', ref => ref
      .orderBy('sort_cat_item_color_minifig_exception', 'asc')).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as OrderItem;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    this.orderItemSubscription = orderItems$.pipe(
      tap(orderItemList => {
        this.itemState.set(ORDER_ITEM_DATA, orderItemList);
        this.orderItems = orderItemList;
      })
    ).subscribe();
    // End order items

    // Order messages
    const messageItems$ = this.afs.collection('orders/' + order_source + '_' + order_id + '/messages', ref => ref
      .orderBy('messageID', 'desc')).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as OrderMessage;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    this.orderMessageSubscription = messageItems$.pipe(
      tap(orderMessageList => {
        this.orderMessageState.set(ORDER_MESSAGE_DATA, orderMessageList);
        this.orderMessageItems = orderMessageList;
      })
    ).subscribe();
    // End Order messages

    // Order document itself
    const order$ = this.afs.doc('orders/' + order_source + '_' + order_id).valueChanges();
    this.orderSubscription = order$.pipe(
      tap(orderList => {
        this.orderState.set(ORDER_DATA, orderList);
        this.order = orderList;
      })
    ).subscribe(res => {
      // Notes on Buyer
      console.log('Getting notes on ' + this.order.buyer_name);
      const notesOnBuyerItems$ = this.afs.doc('notes_on_buyer/' + this.order.buyer_name).valueChanges();
      this.notesOnBuyerSubscription = notesOnBuyerItems$.pipe(
        tap(buyerNotesList => {
          this.notesOnBuyerState.set(BUYER_NOTES_DATA, buyerNotesList);
          this.notesOnBuyer = buyerNotesList;
        })
      ).subscribe();
      }
    );
  }

  updateNoteOnBuyer(buyer_name: string) {
    const note = this.myNoteForm.value.note;
    console.log('Saving note for buyer ' + buyer_name + ': ' + note);

    // Log message
    const message = 'Saving note to user ' + buyer_name;
    this.orderService.sendRemoteLogMessage(null, 0, message, 'success', 'UI', note);

    const noteOnBuyerItem = {
      note: note,
      buyer_name: buyer_name,
      timestamp: new Date(),
      visible: true
    };
    const notesOnBuyerItemDocument = this.afs.doc('notes_on_buyer/' + buyer_name);
    if (note === '') {
      notesOnBuyerItemDocument.delete();
    } else {
      return notesOnBuyerItemDocument.set(noteOnBuyerItem);
    }
  }

  getDate(date) {
    return date.split('T')[0];
  }
  getTime(date) {
    return date.split('T')[1].split('.')[0];
  }
}
