import { Component, OnInit } from '@angular/core';
import {ToasterService} from 'angular2-toaster';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {makeStateKey, Meta, Title, TransferState} from '@angular/platform-browser';
import {OrderService} from '../order-overview/order.service';
import {switchMap, tap} from 'rxjs/operators';
import {Item} from '../expenses-page/expenses-page.component';

const POSTLIST_ITEM_DATA = makeStateKey<any>('balance');

@Component({
  selector: 'posting-list',
  templateUrl: './posting-list.component.html',
  styleUrls: ['./posting-list.component.scss']
})
export class PostingListComponent {
  private toasterService: ToasterService;
  periodFilterValue;
  postingListItems$: Observable<Item[]>;
  currentPostList$: Observable<{}>;
  currentPostList;
  currentPostListSubscription;
  postingListFilter$: BehaviorSubject<number|null>;
  mySearchForm: FormGroup;

  constructor(
    public route: ActivatedRoute,
    private afs: AngularFirestore,
    private meta: Meta,
    private titleService: Title,
    private logState: TransferState,
    public orderService: OrderService,
    private fb: FormBuilder,
    toasterService: ToasterService,
    private currentPostListState: TransferState,
  ) {
    this.toasterService = toasterService;

    this.postingListFilter$ = new BehaviorSubject(null);
    this.postingListItems$ = combineLatest(
      this.postingListFilter$
    ).pipe(switchMap(([period]) =>
      afs.collection<Item>('warenpost_postinglist', ref => {
        this.periodFilterValue = period;

        // Basic query
        const query: firebase.firestore.Query = ref;

        // Limit to max 100
        return query.where('is_current', '==', false).orderBy('last_update', 'desc').limit(100);
      }).valueChanges()
    ));
    // current counters
    this.currentPostList$ = this.afs.doc('warenpost_postinglist/current').valueChanges();
    this.currentPostListSubscription = this.currentPostList$.pipe(
      tap(currentPostList => {
        this.currentPostListState.set(POSTLIST_ITEM_DATA, currentPostList);
        this.currentPostList = currentPostList;
      })
    ).subscribe();
    this.postingListItems$.forEach(postingLists => {
      postingLists.forEach(postingList => {
        this.orderService.fillLinkPdfMap(postingList);
      });
    });
  }
  private round(i) {
    return Math.round((parseFloat(i) + 0.00001) * 1000) / 1000;
  }

}
