import {Component, ViewEncapsulation} from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Meta, Title } from '@angular/platform-browser';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { OrderService } from './order.service';
import { ToasterService } from 'angular2-toaster';
import { Observable, BehaviorSubject } from 'rxjs';
import {count, switchMap, tap} from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {FormBuilder, FormGroup, MaxLengthValidator, Validators} from '@angular/forms';
import {computeStyle} from '@angular/animations/browser/src/util';

const PORTOKASSE_ITEM_DATA = makeStateKey<any>('balance');
const POSTINGLIST_CURRENT_ITEM_DATA = makeStateKey<any>('balance');

export interface Item {
  order_id: string;
  order_status: string;
  source: string;
}

@Component({
  selector: 'order-overview',
  templateUrl: './order-overview.component.html',
  styleUrls: ['./order-overview.component.scss']
})

export class OrderOverviewComponent {
  orders$: Observable<Item[]>;
  portoKasse$: Observable<{}>;
  currentPostingList$: Observable<{}>;
  orderStateFilter$: BehaviorSubject<string|null>;
  orderStateFilterValue;
  orderIdFilterValue;
  orderIdFilter$: BehaviorSubject<number|null>;
  public linkOrderMap = {};
  myWarenpostForm: FormGroup;
  myDhlForm: FormGroup;
  myStampsForm: FormGroup;
  mySearchForm: FormGroup;
  addCN22Form;
  addAddressFields = true;
  addPackstationFields = false;
  addPostfilialeFields = false;
  labelItems;
  portoKasse;
  currentPostingList;
  portoKasseSubscription;
  currentPostingListSubscription;
  myWarenpostFormSubscription;
  myStampsFormSubscription;
  myDhlFormSubscription;

  private toasterService: ToasterService;

  constructor(
    private afs: AngularFirestore,
    private meta: Meta,
    private titleService: Title,
    public state: TransferState,
    public orderService: OrderService,
    toasterService: ToasterService,
    private storage: AngularFireStorage,
    public ngxSmartModalService: NgxSmartModalService,
    private fb: FormBuilder,
    private portoKasseState: TransferState,
    private currentPostinglistState: TransferState
  ) {
    this.toasterService = toasterService;
    this.orderStateFilter$ = new BehaviorSubject('PAID');
    this.orderIdFilter$ = new BehaviorSubject(null);
    this.orders$ = combineLatest(
      this.orderStateFilter$,
      this.orderIdFilter$
    ).pipe(switchMap(([order_status, order_id]) =>
      afs.collection<Item>('orders', ref => {
        this.orderStateFilterValue = order_status;
        this.orderIdFilterValue = order_id;

        // On specific order id ignore the status
        if (order_id) {
          order_status = null;
        }
        // On specific order id ignore the status
        if (order_status) {
          order_id = null;
        }

        // Basic query
        let query: firebase.firestore.Query = ref;

        // Order status
        if (order_status) {
          query = query.where('order_status', '==', order_status);
        }
        // Specific order
        if (order_id) {
          console.log('Order id is ' + order_id);
          console.log(order_id);

          query = query.where('order_id', '==', +order_id);
        }
        // PAID oldest first
        if (order_status && order_status === 'PAID') {
          query = query.orderBy('order_date', 'asc');
        // READY largest amount on top
        } else if (order_status && order_status === 'READY') {
          query = query.orderBy('grand_total', 'desc');
        // Else newest first
        } else if (!order_id) {
          query = query.orderBy('order_date', 'desc');
        }
        // Limit to max 100
        return query.limit(100);
      }).valueChanges()
    ));

    this.labelItems = Array.from({length: 9}, (v, k) => k + 1);

    this.defineForms();

    this.orders$.forEach(orders => {
      orders.forEach(order => {
        this.fillLinkOrderMap(order);
      });
    });
    // portokasse
    this.portoKasse$ = this.afs.doc('portokasse/balance').valueChanges();
    this.portoKasseSubscription = this.portoKasse$.pipe(
      tap(portoKasseList => {
        this.portoKasseState.set(PORTOKASSE_ITEM_DATA, portoKasseList);
        this.portoKasse = portoKasseList;
      })
    ).subscribe();
    // current Postinglist
    this.currentPostingList$ = this.afs.doc('warenpost_postinglist/current').valueChanges();
    this.currentPostingListSubscription = this.currentPostingList$.pipe(
      tap(currentPostinglist => {
        this.currentPostinglistState.set(POSTINGLIST_CURRENT_ITEM_DATA, currentPostinglist);
        this.currentPostingList = currentPostinglist;
      })
    ).subscribe();
  }
  defineForms() {
    this.myWarenpostForm = this.fb.group({
      shipping_label_index: ['', Validators.required],
      shipping_label_product_code: ['', Validators.required],
      shipping_provider: 'Warenpost',
      cn22_quantity: '',
      cn22_weight: '',
      cn22_value: '',
      cn22_classification_code: '',
      cn22_country: '',
      address_line_1: ['', Validators.required],
      address_line_2: ['', Validators.required],
      address_line_3: '',
      address_line_4: '',
      address_line_5: '',
      order_source: ['', Validators.required],
      order_id: ['', Validators.required],
      do_create: false,
      do_delete: false,
      timestamp: new Date()
    });
    this.myWarenpostFormSubscription = this.myWarenpostForm.valueChanges.subscribe(console.log);
    // Make sure to show any errors for pre-filled data
    this.markFormGroupTouched(this.myWarenpostForm);

    this.myDhlForm = this.fb.group({
      shipping_label_index: ['', Validators.required],
      shipping_label_product_code: ['', Validators.required],
      shipping_provider: 'DHL',
      package_weight_kg: ['', Validators.required ],
      email_address: ['', [Validators.required, Validators.maxLength(50)]],
      phone: ['', [Validators.maxLength(20)]],
      print_only_when_encodable: false,
      delivery_type: ['', Validators.required],
      // Address
      name_1: ['', [Validators.required, Validators.maxLength(50)]],
      name_2: ['', Validators.maxLength(50)],
      name_3: ['', Validators.maxLength(50)],
      address_addition: ['', [Validators.maxLength(35)]],
      // Validators of these fields will be set below based on delivery_type
      street_name: '',
      // Address and Parcelshop
      street_number: '',
      // Packstation and Postfiliale
      post_number: '',
      // Packstation
      packstation_number: '',
      // Postfiliale
      postfilial_number: '',
      // Generic address
      address_zip: ['', [Validators.required, Validators.maxLength(5)]],
      address_city: ['', [Validators.required, Validators.maxLength(35)]],
      order_source: ['', Validators.required],
      order_id: ['', Validators.required],
      do_create: false,
      do_delete: false,
      timestamp: new Date()
    });
    // Make sure to show any errors for pre-filled data
    this.markFormGroupTouched(this.myDhlForm);

    this.myDhlFormSubscription = this.myDhlForm.valueChanges.subscribe(console.log);

    this.myDhlForm.get('delivery_type').valueChanges.subscribe(delivery_type => {
      if (delivery_type === 'Address') {
        console.log('Form says true for delivery_type ' + delivery_type);
        // Update validation
        this.myDhlForm.get('street_name').setValidators([Validators.required, Validators.maxLength(35)]);
        this.myDhlForm.get('street_name').updateValueAndValidity();
        this.myDhlForm.get('street_number').setValidators([Validators.required, Validators.maxLength(10)]);
        this.myDhlForm.get('street_number').updateValueAndValidity();

        if (this.myDhlForm.get('packstation_number') != null) {
          console.log('Resetting validation of packstation_number');
          this.myDhlForm.get('packstation_number').setValidators(null);
          this.myDhlForm.get('packstation_number').markAsUntouched();
          this.myDhlForm.get('packstation_number').updateValueAndValidity();
        }
        if (this.myDhlForm.get('post_number') != null) {
          console.log('Resetting validation of post_number');
          this.myDhlForm.get('post_number').setValidators(null);
          this.myDhlForm.get('post_number').markAsUntouched();
          this.myDhlForm.get('post_number').updateValueAndValidity();

        }
        if (this.myDhlForm.get('postfilial_number') != null) {
          console.log('Resetting validation of postfilial_number');
          this.myDhlForm.get('postfilial_number').setValidators(null);
          this.myDhlForm.get('postfilial_number').markAsUntouched();
          this.myDhlForm.get('postfilial_number').updateValueAndValidity();
        }
        // Flip fields
        this.addAddressFields = true;
        this.addPackstationFields = false;
        this.addPostfilialeFields = false;
      }
      if (delivery_type === 'Packstation') {
        console.log('Form says true for delivery_type ' + delivery_type);
        // Update validation
        if (this.myDhlForm.get('packstation_number') != null) {
          this.myDhlForm.get('packstation_number').setValidators([Validators.required, Validators.maxLength(3), Validators.minLength(3)]);
          this.myDhlForm.get('packstation_number').markAsTouched();
        }
        if (this.myDhlForm.get('post_number') != null) {
          this.myDhlForm.get('post_number').setValidators([Validators.required, Validators.maxLength(10)]);
          this.myDhlForm.get('post_number').markAsTouched();
        }
        if (this.myDhlForm.get('postfilial_number') != null) {
          console.log('Resetting validation of postfilial_number');
          this.myDhlForm.get('postfilial_number').setValidators(null);
          this.myDhlForm.get('postfilial_number').markAsUntouched();
          this.myDhlForm.get('postfilial_number').updateValueAndValidity();
        }
        // reset validators on fields we do not need for this type
        this.myDhlForm.get('street_number').setValidators(null);
        this.myDhlForm.get('street_name').setValidators(null);
        // Flip fields
        this.addAddressFields = false;
        this.addPackstationFields = true;
        this.addPostfilialeFields = false;
      }
      if (delivery_type === 'Postfiliale') {
        console.log('Form says true for delivery_type ' + delivery_type);
        // Update validation
        if (this.myDhlForm.get('packstation_number') != null) {
          console.log('Resetting validation of packstation_number');
          this.myDhlForm.get('packstation_number').setValidators(null);
          this.myDhlForm.get('packstation_number').markAsUntouched();
          this.myDhlForm.get('packstation_number').updateValueAndValidity();

        }
        if (this.myDhlForm.get('postfilial_number') != null) {
          this.myDhlForm.get('postfilial_number').setValidators([Validators.required, Validators.maxLength(3), Validators.minLength(3)]);
          this.myDhlForm.get('postfilial_number').markAsTouched();
        }
        if (this.myDhlForm.get('post_number') != null) {
          this.myDhlForm.get('post_number').setValidators([Validators.required, Validators.maxLength(10)]);
          this.myDhlForm.get('post_number').markAsTouched();
        }
        // Flip fields
        this.addAddressFields = false;
        this.addPackstationFields = false;
        this.addPostfilialeFields = true;
      }
    });
    // Stampscom
      this.myStampsForm = this.fb.group({
      shipping_label_index: ['', Validators.required],
      shipping_label_product_code: ['', Validators.required],
      shipping_label_package_code: ['', Validators.required],
      address_residential: ['unknown', Validators.required],
      shipping_provider: 'USPS',
      package_weight_kg: ['', Validators.required ],
      email_address: ['', [Validators.required, Validators.maxLength(50)]],
      phone: ['', [Validators.maxLength(20)]],
      delivery_type: ['', Validators.required],
      // Address
      name_1: ['', [Validators.required, Validators.maxLength(50)]],
      name_2: ['', Validators.maxLength(50)],
      name_3: ['', Validators.maxLength(50)],
      address_addition: ['', [Validators.maxLength(35)]],
      street_name: ['', Validators.required],
      street_number: ['', Validators.required],
      address_zip: ['', [Validators.required, Validators.maxLength(5)]],
      address_state: ['', [Validators.required, Validators.maxLength(2)]],
      address_city: ['', [Validators.required, Validators.maxLength(35)]],
      order_source: ['', Validators.required],
      order_id: ['', Validators.required],
      do_create: false,
      do_delete: false,
      timestamp: new Date()
    });
    // Make sure to show any errors for pre-filled data
    this.markFormGroupTouched(this.myStampsForm);

    this.myStampsFormSubscription = this.myStampsForm.valueChanges.subscribe(console.log);

    this.myStampsForm.get('delivery_type').valueChanges.subscribe(delivery_type => {
      console.log('Form says true for delivery_type ' + delivery_type);
      // Update validation
      this.myStampsForm.get('street_name').setValidators([Validators.required, Validators.maxLength(35)]);
      this.myStampsForm.get('street_name').updateValueAndValidity();
      this.myStampsForm.get('street_number').setValidators([Validators.required, Validators.maxLength(10)]);
      this.myStampsForm.get('street_number').updateValueAndValidity();

      // Flip fields
      this.addAddressFields = true;
    });

    this.mySearchForm = this.fb.group({
      order_id: ''
    });
    this.mySearchForm.valueChanges.subscribe(console.log);
  }
  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  filterByOrderState(order_state: string|null) {
    this.orderStateFilter$.next(order_state);
  }
  filterByOrderId() {
    this.orderIdFilter$.next(this.mySearchForm.value.order_id);
  }
  fillLinkOrderMap(order) {
    // console.log('fillLinkOrderMap for order ' + order.order_id);
    if ('shipping_label' in order) {
      // console.log('Shipping_label object found for order ' + order.order_id);

      for (const shipping_key of Object.keys(order.shipping_label)) {
        const shipping_label = order.shipping_label[shipping_key];
        // console.log('Shipping label loop for key ' + shipping_key);
        const key = order.order_id + '_' + shipping_label.shipping_label_index;
        if (key in this.linkOrderMap) {
          // console.log('Map already contains key: ' + key + ':' + this.linkOrderMap[key]);
        } else {
          console.log('Getting new signed url for: ' + key);
          // Mark requested to prevent duplicated requests that happen asynchronously
          this.linkOrderMap[key] = '_requested_';
          this.storage.ref(shipping_label.pdf_file_name_in_bucket).getDownloadURL().subscribe(url => {
            if (url) {
              // console.log(url);
              this.linkOrderMap[key] = url;
            }
          });
        }
      }
    } else {
      // console.log('No shipping_label object found so ignoring');
    }
  }
  shippingLabelCreateWarenpost(order) {
    console.log('Shipping label create for order ' + order.order_id);

    this.ngxSmartModalService.getModal('createshippingabel').setData(order, true);
    this.ngxSmartModalService.getModal('createshippingabel').open();
  }
  changeHandler(e) {
    console.log(e);
    this.state = e;
  }
  showCN22(shippingproduct) {
   if (shippingproduct === '10264' || shippingproduct === '10289') {
     console.log('Form says true for prod ' + shippingproduct);
     this.addCN22Form = true;
     return true;
   }
   console.log('Form says false for prod ' + shippingproduct);
   this.addCN22Form = false;
   return false;
  }
  handleSubmit(order) {
    const message = 'Requesting label for ' + order.source + ' order ' + order.order_id;
    this.orderService.sendRemoteLogMessage(order.source, order.order_id, message, 'success', 'UI', null);
  }
  async handleWarenpostReset(order) {
    const message = 'Resetting Warenpost label for order ' + order.order_id + '. You can now start over.';
    this.orderService.sendRemoteLogMessage(order.source, order.order_id, message, 'success', 'UI', null);
    await this.myWarenpostForm.patchValue({'do_delete': true});
    this.myWarenpostForm.reset();
    this.defineForms();
    this.myWarenpostFormSubscription.unsubscribe();

    const defaults = this.orderService.getWarenpostDefaults(order);

    this.myWarenpostForm.patchValue(defaults);
  }
  async handleDhlReset(order) {
    const message = 'Resetting DHL label for order ' + order.order_id + '. You can now start over.';
    this.orderService.sendRemoteLogMessage(order.source, order.order_id, message, 'success', 'UI', null);
    await this.myDhlForm.patchValue({'do_delete': true});
    this.myDhlForm.reset();
    this.defineForms();
    this.myDhlFormSubscription.unsubscribe();

    const defaults = this.orderService.getDhlDefaults(order);
    this.myDhlForm.patchValue(defaults);
  }

  async handleUSPSReset(order) {
    const message = 'Resetting USPS label for order ' + order.order_id + '. You can now start over.';
    this.orderService.sendRemoteLogMessage(order.source, order.order_id, message, 'success', 'UI', null);
    await this.myStampsForm.patchValue({'do_delete': true});
    this.myStampsForm.reset();
    this.defineForms();
    this.myStampsFormSubscription.unsubscribe();

    const defaults = this.orderService.getUSPSDefaults(order);
    this.myStampsForm.patchValue(defaults);
  }

  shippingIdExists(order, id) {
    if ('shipping_label' in order) {
      if (id in order['shipping_label']) {
        return false;
      }
    }
    return true;
  }
  getCountryCode(countrycode) {
    // Work around BrickLink bug: UK is not a country code
    if (countrycode === 'GB') {
      return 'UK';
    }
    return countrycode;
  }
  hasPaymentData(order) {
    if ('payment' in order) {
      if ('date_paid' in order['payment']) {
        return true;
      }
    }
    return false;
  }
  formatEuro(amount) {
    return Number(amount).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  formatWeight(amount) {
    return Number(amount).toLocaleString('de-DE', {minimumFractionDigits: 3, maximumFractionDigits: 3 });
  }
  formatDate(date) {
    return date.split('T')[0];
  }
  calculateTotalBrickLinkUIRounding(order) {
    // Everything non-BL is fine
    if (order.source !== 'BL') {
      return order.grand_total;
    }
    // Work around BrickLink bug: Rounding, then calculating results in different total compared to API
    // See 11076962
    // UI: 99,97 vs API: 99,96
    try {
      return this.round(order.disp_cost.subtotal)
        + this.round(order.disp_cost.shipping)
        + this.round(order.disp_cost.etc1)
        + this.round(order.disp_cost.etc2)
        + this.round(order.disp_cost.insurance)
        - this.round(order.disp_cost.credit)
        - this.round(order.disp_cost.coupon);
    }  catch (e) {
      // In case disp_cost is missing in the document, we need to sync it to get the latest data
      // This happens with older orders that were created before we added this to documents
      console.log('Error while trying to use disp_cost from order ' + order.order_id + '. Try sync!');
      return order.grand_total;
    }
  }

  private round(i) {
    return Math.round((parseFloat(i) + 0.00001) * 100) / 100;
  }
}
