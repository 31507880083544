import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import {switchMap, tap} from 'rxjs/operators';
import { makeStateKey, TransferState} from '@angular/platform-browser';
import { ToasterService } from 'angular2-toaster';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Item} from '../expenses-page/expenses-page.component';
import {AngularFireStorage} from '@angular/fire/storage';

const LOG_MESSAGE_DATA = makeStateKey<any>('logMessages');

@Injectable()
export class OrderService {

  orderStatusQueueItemCollection: AngularFirestoreCollection<any>;
  orderSyncQueueItemCollection: AngularFirestoreCollection<any>;
  orderPickingStartedCollection: AngularFirestoreCollection<any>;
  shippingLabelCancelCollection: AngularFirestoreCollection<any>;
  generatePostingListQueueCollection: AngularFirestoreCollection<any>;
  postingListFilter$: BehaviorSubject<number|null>;
  logMessageCollection: AngularFirestoreCollection<any>;
  logMessages;
  logMessagesSubscription;
  toasterService: ToasterService;
  postingListItems$: Observable<Item[]>;
  public linkPdfMap = {};

  constructor(
    private afs: AngularFirestore,
    private logMessageState: TransferState,
    toasterService: ToasterService,
    private storage: AngularFireStorage,


  ) {
    this.orderStatusQueueItemCollection = this.afs.collection('queue_status');
    this.orderSyncQueueItemCollection = this.afs.collection('queue_sync');
    this.orderPickingStartedCollection = this.afs.collection('queue_picking_started');
    this.logMessageCollection = this.afs.collection('log_messages');
    this.shippingLabelCancelCollection = this.afs.collection('shipping_label_cancel_request');
    this.generatePostingListQueueCollection = this.afs.collection('queue_warenpost_postinglist');
    this.toasterService = toasterService;
    this.showMessages();
    this.postingListFilter$ = new BehaviorSubject(null);
    this.postingListItems$ = combineLatest(
      this.postingListFilter$
    ).pipe(switchMap(([period]) =>
      afs.collection<Item>('warenpost_postinglist', ref => {

        // Basic query
        const query: firebase.firestore.Query = ref;

        // Limit to max 2
        return query.where('is_current', '==', false).orderBy('last_update', 'desc').limit(2);
      }).valueChanges()
    ));
    // this.fillLinkPdfMap();
  }

  showMessages(): void {
    const message = 'Loading app and displaying last log message';
    // Display as first one
    this.toasterService.pop('success', 'Loading app', message);
    // Log to collection
    this.sendRemoteLogMessage('', 0, message, 'success', 'UI', null, false);

    // Log messages
    const logMessages$ = this.afs.collection('log_messages/', ref => ref
      .orderBy('timestamp', 'desc').limit(1)).valueChanges();

    this.logMessagesSubscription = logMessages$.pipe(
      tap(logMessageListList => {
        this.logMessageState.set(LOG_MESSAGE_DATA, logMessageListList);
        this.logMessages = logMessageListList;
        // console.log(logMessageListList);
        this.logMessages.forEach((item, index) => {
          const date = new Date(item.timestamp.seconds * 1000);
          if (item.display_in_app) {
            this.toasterService.pop(item.message_type,   date.toLocaleString(),  item.message);
          } else {
            console.log('Ignored item due to display_in_app is false');
          }
          console.log(item);
        });
      })
    ).subscribe();
  }

  sendRemoteLogMessage(source, order_id, message, message_type = 'success', message_source = 'UI', debug_info = null,
                       display_in_app = true) {
    const logMessageItem = {
      order_id: parseFloat(order_id),
      order_source: source,
      message_source: message_source,
      message: message,
      message_type: message_type,
      debug_info: debug_info,
      display_in_app: display_in_app,
      timestamp: new Date(),
    };
    return this.logMessageCollection.add(logMessageItem);
  }

  createOrderStatusQueueItem(order_id: number, order_source: string, status: string) {
    const message = 'Setting status of ' + order_source + ' order ' + order_id + ' to ' + status;
    this.sendRemoteLogMessage(order_source, order_id, message, 'success', 'UI', null);

    const orderStatusQueueItem = {
      status: status,
      order_id: order_id,
      order_source: order_source,
      processed: false,
      timestamp: new Date(),
    };
    return this.orderStatusQueueItemCollection.add(orderStatusQueueItem);
  }

  createOrderSyncQueueItem(order_id: number, order_source: string) {
    const message = 'Queueing ' + order_source + ' order ' + order_id + ' for sync with upstream service';
    this.sendRemoteLogMessage(order_source, order_id, message, 'success', 'UI', null);

    const orderStatusQueueItem = {
      order_id: order_id,
      order_source: order_source,
      processed: false,
      timestamp: new Date(),
    };
    return this.orderSyncQueueItemCollection.add(orderStatusQueueItem);
  }

  // Is this a BrickLink order
  isBL(order_source) {
    return order_source === 'BL';
  }

  // BrickLink US
  isBLUS(order_source) {
    return order_source === 'BLUS';
  }

  // Is this a BrickOwl order
  isBO(order_source) {
    return order_source === 'BO';
  }

  // Is this a BrickScout order
  isBS(order_source) {
    return order_source === 'BS';
  }

  // OK to switch to status
  statusReadyOK(status): any {
    const okStatus = ['PENDING', 'PROCESSING', null];
    return okStatus.indexOf(status) > -1;
  }

  statusPackedOK(status): any {
    const okStatus = ['PAID', null];
    return okStatus.indexOf(status) > -1;
  }

  statusShippedOK(status): any {
    const okStatus = ['PAID', 'PACKED', null];
    return okStatus.indexOf(status) > -1;
  }

  statusPaidOK(status): any {
    const okStatus = ['PENDING', 'PROCESSING', 'READY', 'UPDATED', null];
    return okStatus.indexOf(status) > -1;
  }

  syncOK(status) {
    return true;
  }
  registerPickingStarted(order) {
    const message = 'Marking order ' + order.order_id + ' as being worked on';
    this.sendRemoteLogMessage(order.source, order.order_id, message, 'success', 'UI', null);

    const pickingStartedItem = {
      order_id: order.order_id,
      order_source: order.source,
      processed: false,
      timestamp: new Date(),
    };
    return this.orderPickingStartedCollection.add(pickingStartedItem);
  }

  htmlDecode(input) {
    const e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  }

  formatEuro(amount) {
    return Number(amount).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  getDhlDefaults(order) {
    const shipping_provider = 'DHL';
    const shipping_label_product_code = 'V01PAK';
    let delivery_type = 'Address';
    let packstation_number = '';
    let postfilial_number = '';
    let post_number = '';

    // Handle Packstation
    if (order['shipping']['address']['address2'].includes('Packstation')) {
      delivery_type = 'Packstation';
      packstation_number = order['shipping']['address']['address2'].replace('Packstation', '').trim();
      post_number = order['shipping']['address']['address1'].replace('Postnummer', '').trim();
    }
    if (order['shipping']['address']['address1'].includes('Packstation')) {
      delivery_type = 'Packstation';
      packstation_number = order['shipping']['address']['address1'].replace('Packstation', '').trim();
      post_number = order['shipping']['address']['address2'].replace('Postnummer', '').trim();
    }
    if (delivery_type === 'Packstation' && post_number.length === 0) {
      // try to get pack station number from the name field
      // We look for a digits in a string
      try {
        const searchMask = '\\d+';
        const regEx = new RegExp(searchMask, 'ig');
        const post_number_result = order['shipping']['address']['name']['full'].match(regEx);
        console.log(post_number);
        try {
          // When multiple results, get the first one
          post_number = post_number_result[0];
        } catch (e) {
          console.log(e);
          // Else just pick the single result
          post_number = post_number_result;
        }
      } catch (e) {
        // Should this all fail, then post_number will simply be empty and the user can edit it
        console.log(e);
      }
    }
    // Handle Postfiliale
    if (order['shipping']['address']['address1'].includes('Postfiliale')) {
      delivery_type = 'Postfiliale';
      postfilial_number = order['shipping']['address']['address1'].replace('Postfiliale', '').trim();
      post_number = order['shipping']['address']['address2'];
    }
    if (order['shipping']['address']['address2'].includes('Postfiliale')) {
      delivery_type = 'Postfiliale';
      postfilial_number = order['shipping']['address']['address2'].replace('Postfiliale', '').trim();
      post_number = order['shipping']['address']['address1'];
    }
    // Handle Postfiliale where DHL Paketshop is used
    if (order['shipping']['address']['address1'].includes('Paketshop')) {
      delivery_type = 'Postfiliale';
      postfilial_number = order['shipping']['address']['address1'].replace('Paketshop', '').replace('DHL', '').trim();
      post_number = order['shipping']['address']['address2'];
    }
    if (order['shipping']['address']['address2'].includes('Paketshop')) {
      delivery_type = 'Postfiliale';
      postfilial_number = order['shipping']['address']['address2'].replace('Paketshop', '').replace('DHL', '').trim();
      post_number = order['shipping']['address']['address1'];
    }
    // Estimated parcel weight
    const parcel_weight = ((order['total_weight'] / 1000) * 1.1) + 0.15;
    console.log('Weight is ' + parcel_weight);

    const response_data = {
      // DHL
      'name_1': this.htmlDecode(order['address_line_1']),
      'name_2': this.htmlDecode(order['shipping']['address']['address2']),
      'name_3': null,
      'delivery_type': delivery_type,
      'email_address': order['buyer_email'],
      'phone': '',
      'print_only_when_encodable': false,
      'street_name': this.htmlDecode(order['shipping']['address']['address1']),
      'street_number': null,
      'address_addition': null,
      'address_zip': this.htmlDecode(order['shipping']['address']['postal_code']),
      'address_city': this.htmlDecode(order['shipping']['address']['city']),
      'package_weight_kg': parcel_weight.toFixed(3),
      // Generic
      'shipping_label_index': this.getNextShippingId(order),
      'shipping_provider': shipping_provider,
      'order_source': order['source'],
      'order_id': order['order_id'],
      'shipping_label_product_code': shipping_label_product_code,
      'do_create': false,
      'timestamp': new Date(),
      'packstation_number': packstation_number,
      'post_number': post_number,
      'postfilial_number': postfilial_number,
    };

    // Enrich with Geo Location data
    if ('geo_location' in order) {
      const fields = ['street_number', 'street_name', 'address_city', 'address_zip'];
      for (const field of fields) {
        if (field in order['geo_location']) {
          response_data[field] = order['geo_location'][field];
          // name 2 should not contain info we already have
          const searchMask = order['geo_location'][field];
          const regEx = new RegExp(searchMask, 'ig');
          response_data.name_2 = response_data.name_2.replace(regEx, '').trim();
        }
      }
    }
    return response_data;
  }

  getUSPSDefaults(order) {
    const shipping_provider = 'USPS';

    // Estimated parcel weight (same formula as Warenpost, see main.py ~L470)
    const parcel_weight = ((order['total_weight'] / 1000) * 1.05) + 0.010;
    console.log('Weight is ' + parcel_weight);

    // Pick correct product based on weight
    // Weight is in grams
    // Mail Daniel dd 20 Sep 2019, 15:10
    // First class max 16 oz -> ~ 454 grams
    let shipping_label_product_code = 'usps_priority_mail';
    if (order['total_weight'] < 454) {
      shipping_label_product_code = 'usps_first_class_mail';
    }
    const delivery_type = 'Address';

    // Shipdate is now + 3 days
    // Mail Daniel dd 20 Sep 2019, 11:52
    const ship_date = new Date();
    ship_date.setDate(ship_date.getDate() + 3);

    const response_data = {
      // USPS
      'name_1': this.htmlDecode(order['address_line_1']),
      'name_2': this.htmlDecode(order['shipping']['address']['address2']),
      'delivery_type': delivery_type,
      'email_address': order['buyer_email'],
      'phone': '',
      'street_name': this.htmlDecode(order['shipping']['address']['address1']),
      'street_number': null,
      'address_addition': null,
      'address_zip': this.htmlDecode(order['shipping']['address']['postal_code']),
      'address_city': this.htmlDecode(order['shipping']['address']['city']),
      'address_state': this.htmlDecode(order['shipping']['address']['state']),
      'package_weight_kg': parcel_weight.toFixed(3),
      // Generic
      'shipping_label_index': this.getNextShippingId(order),
      'shipping_provider': shipping_provider,
      'order_source': order['source'],
      'order_id': order['order_id'],
      'shipping_label_product_code': shipping_label_product_code,
      'shipping_label_package_code': 'package',
      'address_residential': 'unknown',
      'do_create': false,
      'timestamp': ship_date
    };

    // Enrich with Geo Location data
    if ('geo_location' in order) {
      const fields = ['street_number', 'street_name', 'address_city', 'address_zip'];
      for (const field of fields) {
        if (field in order['geo_location']) {
          response_data[field] = order['geo_location'][field];
          // name 2 should not contain info we already have
          const searchMask = order['geo_location'][field];
          const regEx = new RegExp(searchMask, 'ig');
          response_data.name_2 = response_data.name_2.replace(regEx, '').trim();
        }
      }
    }
    return response_data;
  }

  getWarenpostDefaults(order) {
    // Depends on country and order value
    let shipping_label_product_code;

    // International shipments
    const shipping_provider = 'Warenpost';

    // EU
    if (order['is_eu_country']) {
      if (order['order_value_requires_signature']) {
        // Warenpost Int KT EU/USt Unterschrift
        shipping_label_product_code = '10292';
      } else {
        // Warenpost Int KT EU/USt Untracked
        shipping_label_product_code = '10270';
      }
    } else {
      if (order['order_value_requires_signature']) {
        // Warenpost Int KT Non-EU Unterschrift
        shipping_label_product_code = '10289';
      } else {
        // Warenpost Int KT Non-EU Untracked
        shipping_label_product_code = '10264';
      }
    }

    return {
      // Warenpost
      'address_line_1': this.htmlDecode(order['address_line_1']).trim(),
      'address_line_2': this.htmlDecode(order['address_line_2']).trim(),
      'address_line_3': this.htmlDecode(order['address_line_3']).trim(),
      'address_line_4': this.htmlDecode(order['address_line_4']).trim(),
      'address_line_5': this.htmlDecode(order['address_line_5']).trim(),
      'cn22_classification_code': '9503',
      'cn22_country': 'DK',
      'cn22_quantity': order['total_count'],
      'cn22_weight': (order['total_weight'] / 1000).toFixed(3),
      'cn22_value': Math.floor(order['subtotal']),
      // Generic
      'shipping_label_index': this.getNextShippingId(order),
      'shipping_provider': shipping_provider,
      'order_source': order['source'],
      'order_id': order['order_id'],
      'shipping_label_product_code': shipping_label_product_code,
      'do_create': false,
      'timestamp': new Date()
    };
  }

  getNextShippingId(order) {
    if ('shipping_label' in order) {
      for (let counter = 1; counter < 10; counter++) {
        if (!(counter in order['shipping_label'])) {
          return counter.toString();
        }
      }
    }
    return '1';
  }

  deleteDHLLabelPossible(shipping_provider, label_create_datetime) {
    // Only DHL can be cancelled
    if (shipping_provider !== 'DHL') {
      return false;
    }
    // Cancel is only possible on day of creation before 18:00.
    // When create after 18:00, it is possible until the next day 18:00
    const now = new Date();
    const labelcreate = new Date(label_create_datetime);
    // UTC versus CET
    labelcreate.setHours( labelcreate.getHours() + 2 );
    const create_hour = labelcreate.getHours();

    // Get labelcreate date 18pm
    const labelmaxdeldate = new Date(labelcreate);
    labelmaxdeldate.setHours(18);

    if (create_hour >= 18) {
      // Get labelcreate date 18pm NEXT DAY
      labelmaxdeldate.setDate(labelmaxdeldate.getDate() + 1);
    }
    // Then compare to see if delete is still possible
    return now < labelmaxdeldate;
  }

  deleteUSPSLabelPossible(shipping_provider, order_status) {
    // Only USPS can be cancelled like this
    if (shipping_provider !== 'USPS') {
      return false;
    }
    // Cancel is only possible when order is not yet shipped
    if (order_status === 'SHIPPED' || order_status === 'RECEIVED' || order_status === 'COMPLETED') {
      return false;
    }
    return true;
  }

  deleteLabel(order, shipping_obj) {
    // Identifier to cancel
    let tracking_id;

    if (shipping_obj.shipping_provider === 'DHL') {
      tracking_id = shipping_obj.tracking_id;
    }

    if (shipping_obj.shipping_provider === 'USPS') {
      tracking_id = shipping_obj.label_id;
    }

    const message = 'Request to delete shipping label ID ' + shipping_obj.shipping_label_index + ' from order ' + order.order_id
      + ' with tracking ID ' + tracking_id;
    this.sendRemoteLogMessage(order.source, order.order_id, message, 'success', 'UI', null);

    const shippingLabelCancelRequestItem = {
      order_id: order.order_id,
      order_source: order.source,
      shipping_label_id: shipping_obj.shipping_label_index,
      tracking_id: tracking_id,
      shipping_provider: shipping_obj.shipping_provider,
      processed: false,
      timestamp: new Date(),
    };
    return this.shippingLabelCancelCollection.add(shippingLabelCancelRequestItem);
  }
  createNewPostingList() {
    const message = 'Request to generate new Warenpost Posting List';
    this.sendRemoteLogMessage('', '', message, 'success', 'UI', null);

    const generatePostingListQueueItem = {
      processed: false,
      timestamp: new Date(),
    };
    return this.generatePostingListQueueCollection.add(generatePostingListQueueItem);
  }

  fillLinkPdfMap(posting_list) {
    if ('pdf_file_name_in_bucket' in posting_list) {
      // console.log('fillPdfOrderMap for order ' + posting_list.pdf_file_name_in_bucket);
      const key = posting_list.document_name;
      if (key in this.linkPdfMap) {
        // console.log('Map already contains key: ' + key + ':' + this.linkOrderMap[key]);
      } else {
        // console.log('Getting new signed url for: ' + key);
        // Mark requested to prevent duplicated requests that happen asynchronously
        this.linkPdfMap[key] = '_requested_';
        this.storage.ref(posting_list.pdf_file_name_in_bucket).getDownloadURL().subscribe(url => {
          if (url) {
            console.log(url);
            this.linkPdfMap[key] = url;
          }
        });
      }
    } else {
      // console.log('No pdf_file_name_in_bucket object found so ignoring');
    }
  }
}
