import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/auth.guard';
import { UserLoginComponent } from './ui/user-login/user-login.component';
import { HomePageComponent } from './ui/home-page/home-page.component';
import { OrderOverviewComponent } from './ui/order-overview/order-overview.component';
import { PackingSlipComponent } from './ui/packing-slip/packing-slip.component';
import { SystemLogsComponent } from './ui/system-logs/system-logs.component';
import { SystemSettingsComponent } from './ui/system-settings/system-settings.component';
import { ExpensesPageComponent } from './ui/expenses-page/expenses-page.component';
import { ExpenseDetailPageComponent } from './ui/expensesdetail-page/expensedetail-page.component';
import { QuantityChangelogComponent } from './ui/quantity-changelog/quantity-changelog.component';
import { PostingListComponent } from './ui/posting-list/posting-list.component';


const routes: Routes = [
  { path: '', component: OrderOverviewComponent,  canActivate: [AuthGuard] },
  { path: 'login', component: UserLoginComponent },
  { path: 'orders', component: OrderOverviewComponent, canActivate: [AuthGuard] },
  { path: 'logs',  component: SystemLogsComponent,  canActivate: [AuthGuard] },
  { path: 'quantity-changelog',  component: QuantityChangelogComponent,  canActivate: [AuthGuard] },
  { path: 'settings',  component: SystemSettingsComponent,  canActivate: [AuthGuard] },
  { path: 'packing-slip/:source/:id', component: PackingSlipComponent, canActivate: [AuthGuard] },
  { path: 'expenses', component: ExpensesPageComponent, canActivate: [AuthGuard] },
  { path: 'expenses/:provider/:period', component: ExpenseDetailPageComponent, canActivate: [AuthGuard] },
  { path: 'posting-list', component: PostingListComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
