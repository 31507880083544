import {Component, ViewEncapsulation} from '@angular/core';
import { AuthService } from './core/auth.service';
import { RouterOutlet } from '@angular/router';
import { slider, transformer, fader, stepper } from './route-animations';
import { ToasterConfig, ToasterService } from 'angular2-toaster';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ stepper ]
})

export class AppComponent {
  title = 'app';
  private toasterService: ToasterService;

  constructor(
    private auth: AuthService,
    toasterService: ToasterService
  ) {
    this.toasterService = toasterService;
  }

  public config: ToasterConfig =
    new ToasterConfig({
      showCloseButton: true,
      tapToDismiss: false,
      positionClass: 'toast-top-right',
      animation: 'fade',
      timeout: 15000,
      mouseoverTimerStop: true,
      limit: 1
    });

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
  onActivate(event) {
    console.log('Scrolling to top');
    window.scroll(0, 0);
  }
}
