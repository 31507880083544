import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {ToasterService} from 'angular2-toaster';
import {ActivatedRoute} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {DomSanitizer, makeStateKey, Meta, Title, TransferState} from '@angular/platform-browser';
import {OrderService} from '../order-overview/order.service';

const EXPENSE_ITEM_DATA = makeStateKey<any>('barcode');
const EXPENSE_DATA = makeStateKey<any>('period');
const EXPENSE_ITEM_DATA_STAMPS = makeStateKey<any>('barcode');
const EXPENSE_DATA_STAMPS = makeStateKey<any>('period');

export interface Item {
  barcode: string;
  dpag_order_id: string;
  order_id: string;
  order_source: string;
  order_date: string;
  date_shipped: string;
  pdf_file_name_in_bucket: string;
  stamp_name: string;
  stamp_png_html_src: string;
  stamp_product: string;
  stamp_value: number;
  timestamp_processed: string;
}

export interface StampsComItem {
  cancelled: boolean;
  barcode: string;
  order_id: string;
  order_source: string;
  // order_date: string;
  // date_shipped: string;
  pdf_file_name_in_bucket: string;
  shipengine_label_id: string;
  shipengine_shipment_id: string;
  stamp_name: string;
  stamp_png_html_src: string;
  stamp_product: string;
  stamp_value: number;
  timestamp_processed: string;
}

@Component({
  selector: 'expensedetail-page',
  templateUrl: './expensedetail-page.component.html',
  styleUrls: ['./expensedetail-page.component.scss']
})

export class ExpenseDetailPageComponent implements OnInit {

  // Warenpost
  expenseDetailItems;
  expenseDocument;
  expenseDetailSubscription;
  expensesSubscription;
  // Stampscom
  expenseStampsComDetailItems;
  expenseStampsComDetailSubscription;
  expensesStampsComSubscription;
  private toasterService: ToasterService;

  constructor(
    public route: ActivatedRoute,
    private afs: AngularFirestore,
    private meta: Meta,
    private titleService: Title,
    private itemState: TransferState,
    private orderState: TransferState,
    public orderService: OrderService,
    toasterService: ToasterService,
    private sanitizer: DomSanitizer
  ) {
    this.toasterService = toasterService;
  }


  ngOnInit() {
    const period = this.route.snapshot.params['period'];
    console.log('Period ' + period);

    // Params
    console.log(this.route.snapshot.params);

    // Warenpost
    if (this.route.snapshot.params['provider'] === 'warenpost') {
      // Expense detail items
      const expenseDetailItems$ = this.afs.collection('warenpost_expenses/' + period + '/details', ref => ref
        .orderBy('timestamp_processed', 'asc')).snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as Item;
            const id = a.payload.doc.id;
            console.log('Processing ' + id);
            return { id, ...data };
          });
        })
      );
      this.expenseDetailSubscription = expenseDetailItems$.pipe(
        tap(expenseDetailItemList => {
          this.itemState.set(EXPENSE_ITEM_DATA, expenseDetailItemList);
          this.expenseDetailItems = expenseDetailItemList;
        })
      ).subscribe();
      // End order items

      // Expense document itself
      const expense$ = this.afs.doc('warenpost_expenses/' + period).valueChanges();
      this.expensesSubscription = expense$.pipe(
        tap(expenseList => {
          this.orderState.set(EXPENSE_DATA, expenseList);
          this.expenseDocument = expenseList;
          console.log(expenseList);
        })
      ).subscribe();
    }


    // StampsCom
    if (this.route.snapshot.params['provider'] === 'stampscom') {
      // Expense detail items
      const expenseStampsComDetailItems$ = this.afs.collection('stampscom_expenses/' + period + '/details', ref => ref
      .orderBy('timestamp_processed', 'asc')).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as StampsComItem;
          const id = a.payload.doc.id;
          console.log('Processing ' + id);
          return { id, ...data };
        });
      })
    );
    this.expenseStampsComDetailSubscription = expenseStampsComDetailItems$.pipe(
      tap(expenseStampsComDetailItemList => {
        this.itemState.set(EXPENSE_ITEM_DATA_STAMPS, expenseStampsComDetailItemList);
        this.expenseStampsComDetailItems = expenseStampsComDetailItemList;
      })
    ).subscribe();
    // End order items

    // Expense document itself
    const expenseStampsCom$ = this.afs.doc('stampscom_expenses/' + period).valueChanges();
    this.expensesStampsComSubscription = expenseStampsCom$.pipe(
      tap(expenseList => {
        this.orderState.set(EXPENSE_DATA_STAMPS, expenseList);
        this.expenseDocument = expenseList;
        console.log(expenseList);
      })
    ).subscribe();
  }
}
public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
